<template>
<div class="container">
    <div class="table-container">
        <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
            <el-table-column label="ID" min-width="150" :show-overflow-tooltip="true" align="center">
                <template slot-scope="scope">
                    {{scope.row.id}}
                </template>
            </el-table-column>
            <el-table-column label="用户名" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.username}}
                </template>
            </el-table-column>
            <el-table-column label="联系方式" min-width="100" align="center">
                <template slot-scope="scope">
                    {{scope.row.phone}}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" min-width="155" align="center">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
</div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        type: {
            type: String,
        },
        id: {
            type: Number,
        }
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],
        };
    },
    created() {
        // this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) { // 页码改变
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() { // 获取班级列表
            Core.Api.Course.studentList(
                this.currentPage,
                this.pageSize,
            ).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },
    }
};
</script>

<style  lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>